import { Component, OnInit } from "@angular/core";
import { IModalWindowOptions } from "../../../shared/components/modal-window/modal-options.interface";
import { TemplateApiService } from "../../services/template-api.service";
import { ModalWindowService } from "../../../shared/components/modal-window/modal-window.service";
import { ITripTemplateShort } from "../../trip-templates.models";
import { tap } from "rxjs/operators";

@Component({
    selector: "ge-delete-trip-template-modal",
    templateUrl: "./delete-trip-template-modal.component.html",
    styleUrl: "./delete-trip-template-modal.component.scss",
})
export class DeleteTripTemplateModalComponent implements OnInit {
    data: any = {};
    template: ITripTemplateShort;

    modalWindowOptions: IModalWindowOptions = {
        title: "tripTemplates.deleteModal.title",
        buttons: [
            {
                label: "tripTemplates.deleteModal.submitButtonLabel",
                click: this.delete.bind(this),
            },
        ],
    };

    constructor(
        private templateApiService: TemplateApiService,
        private modalWindowService: ModalWindowService,
    ) {}

    ngOnInit() {
        this.template = this.data.template;
    }

    private delete() {
        return this.templateApiService
            .deleteTripTemplate(this.template.id)
            .pipe(
                tap(() => {
                    this.modalWindowService.close();
                }),
            );
    }
}
